@use 'sass:map';
@use 'sass:list';
@use "sass:string";
@use 'sass:meta';
@use '@angular/material' as mat;
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// default material theme
$materialDefaultLightTheme: mat.m2-define-light-theme((
	color: (
		primary: mat.m2-define-palette(mat.$m2-blue-grey-palette, 700),
		accent: mat.m2-define-palette(mat.$m2-blue-grey-palette, 700)
	),
	typography: mat.m2-define-typography-config(
		$headline-1:     mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
		$headline-2:     mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
		$headline-3:     mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
		$headline-4:     mat.m2-define-typography-level(34px, 40px, 400),
		$headline-5:      mat.m2-define-typography-level(24px, 32px, 400),
		$headline-6:         mat.m2-define-typography-level(20px, 32px, 500),
		$subtitle-2:  mat.m2-define-typography-level(16px, 28px, 400),
		$subtitle-1:  mat.m2-define-typography-level(15px, 24px, 400),
		$body-2:        mat.m2-define-typography-level(14px, 24px, 500),
		$body-1:        mat.m2-define-typography-level(14px, 20px, 400),
		$button:        mat.m2-define-typography-level(14px, 14px, 500),
		$caption:       mat.m2-define-typography-level(12px, 20px, 400),
		$overline:        mat.m2-define-typography-level(10px, 20px, 400),
	)
	// typography: mat.define-typography-config(
	// 	 $body-1: mat.define-typography-level(14px, 16px, 500),
	// 	 $body-2: mat.define-typography-level(14px, 16px, 500)
	// )
));

$materialDefaultDarkTheme: mat.m2-define-dark-theme((
	color: (
		primary: mat.m2-define-palette(mat.$m2-blue-grey-palette, 50),
		accent: mat.m2-define-palette(mat.$m2-blue-grey-palette, 50)
	)
));

// @include mat.core-theme($materialDefaultLightTheme);
@include mat.all-component-themes($materialDefaultLightTheme);

// Default material theme
// $default-material-app-theme:   mat.define-light-theme(mat.define-palette(mat.$indigo-palette), mat.define-palette(mat.$pink-palette));
// @include mat.all-component-themes($default-material-app-theme);

// default values for
// mat.define-palette(
// 	$base-palette,
// 	$default: 500,
// 	$lighter: 100,
// 	$darker: 700
// )

@mixin input-background-autofill($backgroundColor, $foregroundColor){
	input {
		&:-webkit-autofill {
			&,
			&:hover,
			&:focus,
			&:active  {
				box-shadow: 0 0 0 100px $backgroundColor inset !important;
				-webkit-box-shadow: 0 0 0 100px $backgroundColor inset !important;
				-webkit-text-fill-color: $foregroundColor !important;
			}
		}
	}
}

@mixin apply-theme-base-colors-into-children($theme){

	$backgroundColorBase: mat.m2-get-color-from-palette(mat.m2-get-color-from-palette($theme, background), card);

	@include input-background-autofill(
		$backgroundColorBase,
		mat.m2-get-color-from-palette(mat.m2-get-color-from-palette($theme, foreground), base)
	);

	--theme-palette-hue-bg: #{$backgroundColorBase};

}

%components-light-theme {
	@include mat.form-field-color($materialDefaultLightTheme);
	// @include mat.button-color($materialDefaultLightTheme);
	// @include mat.card-color($materialDefaultLightTheme);
	@include mat.list-color($materialDefaultLightTheme);
	@include mat.select-color($materialDefaultLightTheme);
	@include mat.divider-color($materialDefaultLightTheme);
	// @include mat.datepicker-color($materialDefaultLightTheme);
}

%components-dark-theme {
	// @include mat.all-component-colors($materialDefaultDarkTheme);
	@include mat.form-field-color($materialDefaultDarkTheme);
	// @include mat.button-color($materialDefaultLightTheme);
	// @include mat.card-color($materialDefaultDarkTheme);
	@include mat.list-color($materialDefaultDarkTheme);
	@include mat.select-color($materialDefaultDarkTheme);
	@include mat.divider-color($materialDefaultDarkTheme);
	// @include mat.datepicker-color($materialDefaultDarkTheme);
}

@mixin angular-material-color-variant(
  $attrName,
  $paletteName,
  $palette,
  $paletteSettings,
  $variation
){

  $variationAttr: '';
  $variationValue: '';

  @if $variation != base {
    $variationAttr: '-#{$variation}';
  }

  @if meta.type-of($variation) == number or string.slice(string.quote($variation), 1, 1) == "A" {
    $variationValue: $variation;
  } @else {
    $variationValue: map.get($paletteSettings, $variation);
  }

  // background color
  &[#{$attrName}-bg="#{$paletteName}#{$variationAttr}"]{

		&:not([mat-raised-button][disabled]):not([mat-flat-button][disabled]) {

			$my-theme: '';

			// $lightVariations: light, 50, 100, 200, 300, A100, A200;

			$contrast: mat.m2-get-color-from-palette(
				$palette,
				$variationValue + '-contrast'
			);

			@if $contrast != white {

				$my-theme: mat.m2-define-light-theme((
				color: (
					primary: $palette,
					accent: $palette,
				)
				));

				@extend %components-light-theme;

			}@else{

				$my-theme: mat.m2-define-dark-theme((
				color: (
					primary: $palette,
					accent: $palette,
				)
				));

				@extend %components-dark-theme;

			}

			// background
			// all hue colors are set as primary to be used as a alternative
			$backgroundColor: mat.m2-get-color-from-palette(
				map.get(mat.m2-get-color-config($my-theme), 'primary'),
				$variationValue
			);
			background-color: $backgroundColor;
			--theme-palette-hue-bg: #{$backgroundColor};

			// foreground
			// all hue colors are set as primary to be used as a alternative
			$textColor: mat.m2-get-color-from-palette(
				map.get(mat.m2-get-color-config($my-theme), foreground),
				text
			);
			color: $textColor;
			--outline-color-hover: #{$textColor};

			@include input-background-autofill($backgroundColor, $textColor);

			.mat-mdc-input-element::-webkit-input-placeholder {
				color: mat.m2-get-color-from-palette(
					map.get(mat.m2-get-color-config($my-theme), foreground),
					disabled-text
				);
			}

			.mat-mdc-progress-spinner circle {
				stroke: $textColor;
			}

			mat-datepicker-toggle {
				color: currentColor;
			}

		}

	}

  // foreground color
	$fgColor: mat.m2-get-color-from-palette(
		$palette,
		$variationValue
	);

	$fgConstrastColor: mat.m2-get-color-from-palette(
		$palette,
		'#{$variationValue}-contrast'
	);

  &[#{$attrName}-fg="#{$paletteName}#{$variationAttr}"]{
		color: $fgColor;
  }

	// css color palette variables
	--color-palette-#{$paletteName}#{$variationAttr}: #{$fgColor};
	--color-palette-#{$paletteName}#{$variationAttr}-contrast: #{$fgConstrastColor};

}

// Apply in elements attribute
// [theme-palette-hue-bg]="primary-dark"
// [theme-palette-hue-fg]="primary-light"
@mixin angular-material-theme-color-variants($paletteName, $palette, $paletteSettings){

  $attrName: 'theme-palette-hue';

  $variants: base, light, dark, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700;

  @each $variant in $variants {
    @include angular-material-color-variant(
      $attrName,
      $paletteName,
      $palette,
      $paletteSettings,
      $variant
    );
  }

}

// mat.define-palette($base-palette, $default: 500, $lighter: 100, $darker: 700, $text: $default)
@function angular-material-get-theme-palette-settings($palette-settings){
  @return mat.m2-define-palette(
    map.get($palette-settings, palette),
    map.get($palette-settings, base),
    map.get($palette-settings, light)
  );
}

// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// @import './themes/namoa';
// @import './themes/customer-demo.scss';

/*
    result[0] = shadeColor(color, 0.9   ); //----> 50
    result[1] = shadeColor(color, 0.7   ); //----> 100
    result[2] = shadeColor(color, 0.5   ); //----> 200
    result[3] = shadeColor(color, 0.333 ); //----> 300
    result[4] = shadeColor(color, 0.166 ); //----> 400
    result[5] = shadeColor(color, 0     ); //----> 500
    result[6] = shadeColor(color, -0.125); //----> 600
    result[7] = shadeColor(color, -0.25 ); //----> 700
    result[8] = shadeColor(color, -0.375); //----> 800
    result[9] = shadeColor(color, -0.5  ); //----> 900

    result[10] = shadeColor(color, 0.7  ); //----> A100
    result[11] = shadeColor(color, 0.5  ); //----> A200
    result[12] = shadeColor(color, 0.166); //----> A400
    result[13] = shadeColor(color, -0.25); //----> A700
*/
